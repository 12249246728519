globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"0dcc135"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser runtime.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import '@sentry/tracing'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN ?? ''

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME.replace(/\//g, '_'),
    denyUrls: [
      // Block errors generated by browser extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    beforeSendTransaction(event) {
      // Modify or drop the event here
      if (event.transaction?.startsWith('/api/isReady') || event.transaction?.startsWith('/api/ping')) {
        // Don't send the event to Sentry
        return null
      }
      return event
    },
    tracesSampler: samplingContext => {
      // Begins with /
      // Configure sampling rate based on predicates i.e. log more of the transactions you care about

      // Increased sampling rate for prod environments
      if (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME.startsWith('prod/')) {
        return 0.5
      }

      // Default sample rate for all others (replaces tracesSampleRate)
      return 0.2
    },
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
